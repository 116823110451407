<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";


/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "升等任務查詢",
      items: [
        {
          text: "用戶等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "升等任務查詢",
          active: true,
        }
      ],
      upgradeTaskList: [],
      searchData:{
        memberName: '',
        memberCode: '',
        startDate: null,
        endDate: null,
        badgeId: null
      },

      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields1: [
        {
          key: "name",
          label: "升等任務名稱",
          sortable: false,
          class: "width-auto center text-nowrap"
        },
        {
          key: "publish_count",
          label: "發出金徽章數量",
          sortable: false,
          class: "width-auto center text-nowrap"
        },
        {
          key: "action",
          label: "",
          stickyColumn: true,
          class: "width-auto center"
        }
      ],
      fields2: [
        {
          key: "member_name",
          label: "姓名",
          sortable: false
        },
        {
          key: "name",
          label: "升等任務名稱",
          sortable: false
        },
        {
          key: "time",
          label: "獲得時間",
          sortable: false
        }
      ],
      tab: 1
    };
  },
  computed:{
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.reloadList()
  },
  methods: {
    reloadList () {
      this.$refs.taskTable.refresh()
    },
    getTaskList(_table, _callback){
      const vm = this

      const searchParam = {
        start_date: this.$root.common.getNowTime('date', this.searchData.startDate),
        end_date: this.$root.common.getNowTime('date', this.searchData.endDate),
        badge_id: this.searchData.badgeId
      }

      this.$root.apis.getUpgradeTaskList(searchParam, 
        function (_response) {
          if (typeof _callback === 'function') {
            let result = _response.body.data
            vm.totalRows = result?.total || 0
            vm.currentPage = result?.page || 1
            vm.perPage = result?.per_page || 25
            vm.upgradeTaskList = result
            if (typeof _callback === 'function') {
              vm.tableData = result
              _callback(vm.tableData)
            }
          }
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    getTaskListByMember() {
      const vm = this
      
      const searchParam = {
        member_name: this.searchData.memberName,
        member_code: this.searchData.memberCode,
        start_date: this.$root.common.getNowTime('date', this.searchData.startDate),
        end_date: this.$root.common.getNowTime('date', this.searchData.endDate),
        badge_id: this.searchData.badgeId
      }

      this.$root.apis.getUpgradeTaskListByMember(searchParam, 
        function (_response) {
          if (typeof _callback === 'function') {
            let result = _response.body.data
            vm.totalRows = result?.total || 0
            vm.currentPage = result?.page || 1
            vm.perPage = result?.per_page || 25
            vm.upgradeTaskList = result
            console.log('result', result)
            if (typeof _callback === 'function') {
              vm.tableData = result
              _callback(vm.tableData)
            }
          }
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    checkListByMember() {
      this.changeTab(2)
      this.tableData = []
      this.reloadList()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeTab(_status){
      this.tab = _status
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row.pl-0
    .col-lg-12.mb-2.title-box.row
      .col-lg-9.pl-0
        b-button-group.tab-group
          b-button.mr-2(variant="primary" :class="{off: tab !== 1}" @click="changeTab(1)") 依升等任務查詢
          b-button.mr-2(variant="primary" :class="{off: tab !== 2}" @click="changeTab(2)") 依帳戶查詢
    .col-lg-12.mt-0
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")
            .row(v-if="tab === 1")
              .col-lg-4  
                .form-group
                  label.col-form-label(for="example-date") 開始時間
                  date-picker(
                    v-model="searchData.startDate"
                    @clear="searchData.startDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期"
                  )
              .col-lg-4  
                .form-group
                  label.col-form-label(for="example-date") 結束時間
                  date-picker(
                    v-model="searchData.endDate"
                    @clear="searchData.endDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期"
                  )
              .col-lg-4.mt-1
                .form-group.mb-0
                  label 任務名稱
                  multiselect(
                    :value="upgradeTaskList.find(_task => _task.id === searchData.badgeId) || null"
                    @input="((_option) => searchData.badgeId = _option.id)"
                    :options="upgradeTaskList",
                    placeholder="請選擇任務名稱",
                    trackBy="id"
                    label="name"
                    select-label=""
                    :multiple="false")

            .row(v-if="tab === 2")
              .col-lg-4  
                .form-group
                  label.col-form-label 姓名
                  input.form-control(v-model="searchData.memberName" placeholder="請輸入帳戶姓名")
              .col-lg-4  
                .form-group
                  label.col-form-label 用戶編號
                  input.form-control(v-model="searchData.memberCode" placeholder="請輸入用戶編號")
              .col-lg-4.mt-1
                .form-group.mb-0
                  label 任務名稱
                  multiselect(
                    :value="upgradeTaskList.find(_task => _task.id === searchData.badgeId) || null"
                    @input="((_option) => searchData.badgeId = _option.id)"
                    :options="upgradeTaskList",
                    placeholder="請選擇任務名稱",
                    trackBy="id"
                    label="name"
                    select-label=""
                    :multiple="false")
              .col-lg-4  
                .form-group
                  label.col-form-label(for="example-date") 開始時間
                  date-picker(
                    v-model="searchData.startDate"
                    @clear="searchData.startDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期"
                  )
              .col-lg-4  
                .form-group
                  label.col-form-label(for="example-date") 結束時間
                  date-picker(
                    v-model="searchData.endDate"
                    @clear="searchData.endDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期"
                  )
            .row
              .col-lg-12.mt-2.d-flex.justify-content-end.p-0.m-0
                b-button.width-md(variant="primary" @click="reloadList") 查詢
      //- 表格
      .card.mt-2
        .card-body
          .header-row.mb-3
            .header-title 升等任務查詢清單
            p.mr-3.mt-2 符合條件資料共計有 {{ tableData.length }} 筆資料
            b-button.width-md(variant="primary") 匯出
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="taskTable"
                :items="tab === 1 ? getTaskList : getTaskListByMember", 
                :fields="tab === 1 ? fields1 : fields2", 
                responsive)

                template(v-slot:cell(member_name)="data")
                  span(v-if="data.item.member_name") {{ data.item.member_name }}
                  span(v-else) -

                template(v-slot:cell(name)="data")
                  span(v-if="data.item.name") {{ data.item.name }}
                  span(v-else) -
                
                template(v-slot:cell(publish_count)="data")
                  span(v-if="data.item.publish_count !== null || data.item.publish_count !== undefined") {{ data.item.publish_count }}
                  span(v-else) -
                
                template(v-slot:cell(time)="data")
                  span(v-if="data.item.time") {{ data.item.publish_count }}
                  span(v-else) -

                template(v-slot:cell(action)="data")
                    b-button.width-md.mr-2(variant="primary" @click="checkListByMember") 查看名單
                    b-button.width-md(variant="primary") 匯出名單
          .row.mb-md-2
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  background: #f1f5f7

::v-deep td
  &.b-table-sticky-column:last-child
    background: #fff !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  &.text-nowrap
    white-space: nowrap
</style>
